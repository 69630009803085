<template>
    <div class="order-edit">
      <invoice-page order-type="invoice" :invoice-edit="true"></invoice-page>
    </div>

</template>

<script>
    import InvoicePage from "../../../components/invoice/InvoicePage";

    export default {
        name: "Order",
        components: {InvoicePage},

    }
</script>

<style scoped>

</style>
